import { callApi, basicAuthHeader } from './Request'

/**
 * Get Table
 * @param {String} url .
 * @param {String} table .
 * @param {String} order .
 * @param {String} item .
 * @param {Function} success .
 * @param {Function} error .
 * @returns {Object} Patient Object
 */
const getTable = (url, table, order, item, success, error) => callApi(url, {
  headers: basicAuthHeader(),
  method: 'POST',
  body: JSON.stringify({
    table,
    columns: {
      ...item,
    },
    order
  }),
}, success, error)

export default { getTable }
