const messages = {
  map: 'Ver en maps',
  thanks: 'Gracias por agendar, ya queremos verte!',
  addCalendar: 'Agregar a mi calendario',
  recommendation: 'Recomendaciones',
  seeMaps: 'Ver en maps',
  newAppointment: 'REAGENDAR CITA'
}

export default messages
