/* eslint-disable camelcase */
/* eslint-disable complexity */
import React from 'react'
import moment from 'moment-timezone'
import 'moment/locale/es'
import styles from './Success.module.css'
import messages from './Success.messages'
import cosmo from '../../Assets/images/png/astro.png'
import cosmonaut from '../../Assets/images/svg/cosmonautBlue.svg'
import ok from '../../Assets/images/svg/ok.svg'
import clock from '../../Assets/images/svg/clock.svg'
import implant from '../../Assets/images/svg/implant.svg'
import calendar from '../../Assets/images/svg/calendar.svg'
import mapa from '../../Assets/images/svg/mapa.svg'
// import Referral from '../Referral/Referral'
import Footer from '../Footer/Footer'
import FullModal from '../FullModal/FullModal'
import Confirm from '../Confirm/Confirm'
import Utils from '../../Utils/utils'

/**
 * Success Component
 * @return {void}
 */
class Success extends React.Component {
  /**
   * Constructor
   * @param {*} props .
   */
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      showMap: true,
      imageMap: ''
    }
  }

  /**
   * Component Did Mount
   * @return {void}
   */
  componentDidMount() {
    const { imageMap } = this.state
    const { branch } = this.props
    if (!imageMap) {
      this.getImage(branch)
    }
  }

  resume = () => {
    const {
      branch, dateSelected, userData, timeZone
    } = this.props
    const {
      Center_Name, Street, Number, Neighborhood, Appointment_Type_Id
    } = branch
    const { firstName, country } = userData

    const address = `${Street} ${Number}, ${Neighborhood}`
    const date = moment(dateSelected).format('dddd DD MMMM')
    const time = timeZone ? Utils.getDateByCountryTimeZone(dateSelected, country, Appointment_Type_Id)
      : Utils.getDateByCountryTimeZone(dateSelected, 'México', Appointment_Type_Id)
    return (
      <div className={styles.CenterContainer}>
        <div
          className={styles.Item}
        >
          <img
            className={styles.Icon}
            alt="icon"
            src={cosmonaut}
          />
          <div className={styles.InfoDate}>
            <p className={styles.Name}>{firstName}</p>
          </div>
        </div>
        <div
          className={styles.ItemSecond}
        >
          {branch.Center_Icon ? (
            <img
              className={styles.Icon}
              alt="icon"
              src={branch.Center_Icon}
            />
          ) : (
            <img
              className={styles.Icon}
              alt="icon"
              src={implant}
            />
          )}
          <div className={styles.AddressContainer}>
            <p className={styles.Center}>{Center_Name}</p>
            <p className={styles.Address}>{address}</p>
          </div>
        </div>
        <div
          className={styles.ItemSecond}
        >
          <img
            className={styles.Icon}
            alt="icon"
            src={clock}
          />
          <div className={styles.InfoDate}>
            <p className={styles.Dates}>{date}</p>
            <p className={styles.Address}>{time}</p>
          </div>
        </div>
      </div>
    )
  }

  buildCalendarLink = () => {
    const { appointment, branch } = this.props
    const {
      Zone, Street, Apt, Neighborhood, State, country, Zip
    } = branch
    const dateTime = appointment.datetime
    const timeStart = moment(dateTime).format('YYYYMMDDTHHmmss')
    const timeEnd = moment(dateTime)
      .add(appointment.duration, 'minutes')
      .format('YYYYMMDDTHHmmss')

    const text = appointment.type
    const location = `${Zone} - ${Street}, ${encodeURIComponent(branch.Number)}, 
    ${branch.Apt === '' ? '' : `${encodeURIComponent(Apt)},`} ${
      country === 'México' ? `${Neighborhood}, ${Zip},` : ''
    } ${State}`
    const details = `${location}%0A%0AVer/modificar cita:%0A${appointment.confirmationPage}`

    const link = `https://calendar.google.com/calendar/r/eventedit?dates=${timeStart}/${timeEnd}&text=${text}&location=${location}&details=${details}&sf=true`
    return link
  }

  showModal = () => {
    const { showModal } = this.state
    this.setState({ showModal: !showModal })
  }

  /**
   * Map Center.
   * @param {Object} branch .
   * @param {Object} error .
   * @returns {void} .
   */
  getImage = (branch, error) => {
    let imageMap = branch && branch.Map_Image
    const url = 'https://maps.googleapis.com/maps/api/staticmap'
    const zoom = 14
    let finalURL = `${url}?center=${branch.Latitude},${branch.Longitude}&zoom=${zoom}&scale=2&size=${400}x${250}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
    finalURL = `${finalURL}&markers=color:red|${branch.Latitude},${branch.Longitude}`
    imageMap = error ? branch && branch.Map_Image : finalURL
    this.setState({ imageMap })
  }

  /**
   * Render
   * @returns {void}
   */
  render() {
    const {
      branch, userData, dateSelected, newAppointment, nextStep, timeZone
    } = this.props
    const { Notes } = branch
    const { country } = userData
    const { showModal, showMap, imageMap } = this.state

    const linkCalendar = this.buildCalendarLink()
    const content = (
      <Confirm
        branch={branch}
        dateSelected={dateSelected}
        userData={userData}
        showModal={this.showModal}
        newAppointment={newAppointment}
        nextStep={nextStep}
        country={country}
        timeZone={timeZone}
      />
    )

    const showImageMap = branch && showMap

    return (
      <div className={styles.ContainerSuccess}>
        <FullModal
          open={showModal}
          content={content}
        />
        <img
          className={styles.Cosmonaut}
          src={cosmo}
          alt="cosmo"
        />
        <div className={styles.Thanks}>
          <div className={styles.ThanksContainer}>
            <img
              className={styles.Ok}
              src={ok}
              alt="ok"
            />
            <p className={styles.ThanksText}>{messages.thanks}</p>
          </div>
        </div>
        <div className={[styles.Section, Notes ? '' : styles.Center].join(' ')}>
          <div className={styles.ResumeLink}>
            {this.resume()}
            <div className={styles.ContainerLinkCalendar}>
              <img
                className={styles.ImageCalendar}
                alt="calendar"
                src={calendar}
              />
              <a
                href={linkCalendar}
                className={styles.LinkCalendar}
                rel="noopener noreferrer"
                target="_blank"
              >
                {messages.addCalendar}
              </a>
            </div>
          </div>
          { Notes && (
            <div className={styles.Recommendation}>
              <div className={styles.ContainerRec}>
                <p className={styles.TitleRecommendation}>{messages.recommendation}</p>
                <p className={styles.ItemRec}>
                  {Notes}
                </p>
              </div>
            </div>
          )}
        </div>
        <div className={styles.MapImagesContainer}>
          {showImageMap && (
            <div
              className={styles.MapContainer}
              role="button"
              tabIndex={0}
              onClick={() => branch.Map_URL && window.open(branch.Map_URL)}
            >
              <img
                alt="map"
                src={imageMap}
                className={styles.MapImage}
                onError={() => this.getImage(branch, true)}
              />
            </div>
          )}
          {branch && branch.Map_URL && (
            <div
              role="button"
              tabIndex={0}
              className={styles.SeeMaps}
              onClick={() => window.open(branch.Map_URL)}
            >
              <img
                alt="map"
                src={mapa}
                className={styles.IconMap}
              />
              <p>{messages.seeMaps}</p>
            </div>
          )}
        </div>
        <div
          role="button"
          tabIndex={0}
          className={styles.NewAppointment}
          onClick={() => this.showModal()}
        >
          {messages.newAppointment}
        </div>
        <Footer country={country} />
      </div>
    )
  }
}

export default Success
