/* eslint-disable camelcase */
/* eslint-disable complexity */
import React from 'react'
import { withRouter } from 'react-router-dom'
import styles from './Branches.module.css'
import messages from './Branches.messages'
import Utils from '../../Utils/utils'
import implant from '../../Assets/images/svg/implant.svg'

/**
 * Branches Component
 * @return {void}
 */
class Branches extends React.Component {
  /**
   * Constructor
   * @param {*} props .
   */
  constructor(props) {
    super(props)
    this.state = {
      branches: {}
    }
  }

  /**
   * Component Did Mount
   * @return {void}
   */
  componentDidMount = () => {
    const { items } = this.props
    items && this.mapBrachByState(items)
  }

  /**
   * Map Brach By State
   * @param {Object} items .
   * @return {void}
   */
  mapBrachByState = (items) => {
    const branches = {}
    Array.isArray(items) && items.map((branch) => {
      if (branch.Active) {
        if (!branches[branch.State]) {
          branches[branch.State] = []
        }
        branches[branch.State].push(branch)
      }
      return ''
    })
    branches && this.setState({ branches })
  }

  /**
   * Item Component
   * @param {Object} key .
   * @param {Object} item .
   * @return {void}
   */
  itemComponent = (key, item) => (
    <div
      key={key}
      className={styles.ItemContainer}
    >
      <p className={styles.KeyCenter}>{key}</p>
      <div
        className={styles.State}
      >
        {Array.isArray(item) && item.map((branch, index) => {
          const {
            Center_Name, Number, Neighborhood, Street, Timetable
          } = branch
          const { setCurrentBranch } = this.props
          const address = `${Street} ${Number}, ${Neighborhood}`
          return (
            <div
              className={[styles.CenterContainer, index === 0 ? styles.First : ''].join(' ')}
              key={branch.Appointment_Type_Id}
              role="button"
              tabIndex={0}
              onClick={() => setCurrentBranch(branch)}
            >
              {branch.Center_Icon ? (
                <img
                  className={styles.Icon}
                  alt="icon"
                  src={branch.Center_Icon}
                />
              ) : (
                <img
                  className={styles.Icon}
                  alt="icon"
                  src={implant}
                />
              )}
              <div className={styles.AddressContainer}>
                <p className={styles.Center}>{Center_Name}</p>
                <p className={styles.Address}>{address}</p>
                <p className={styles.Schedule}>{Utils.parseTimeTable(Timetable)}</p>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )

  /**
   * Render
   * @returns {void}
   */
  render() {
    const { branches } = this.state
    const { name } = this.props

    const title = window.innerWidth < 700 ? messages.titleMobile : messages.titleDesktop
    const welcome = messages.welcome.replace('{Name}', name)

    return (
      <div className={styles.ContainerBranch}>
        <p className={styles.TitleName}>{welcome}</p>
        <p className={styles.Title}>{title}</p>
        {branches && !Utils.isEmptyObject(branches)
        && Object.entries(branches).map(([key, value]) => this.itemComponent(key, value))}
      </div>
    )
  }
}

export default withRouter(Branches)
