const messages = {
  titleHeader: 'Tu cita se reagendará',
  desc: 'Cancelaremos tu cita y podrás seleccionar un nuevo horario',
  areUSure: '¿Estás seguro de reagendar tu cita?',
  reschedule: 'REAGENDAR',
  textFooter: 'Mantener mi cita',
  error: 'No se pudo cancelar tu cita, intente más tarde'
}

export default messages
