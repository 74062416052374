import React from 'react'
import styles from './Shell.module.css'
import Header from '../Header/Header'

/**
 * Shell component.
 * @returns {void} .
 */
const Shell = ({
  children, headerDisabled
}) => {
  const header = headerDisabled === true ? null : <Header />

  return (
    <div className={styles.Container}>
      {header}
      <div className={styles.ContainerChildren}>{children}</div>
    </div>
  )
}

export default Shell
