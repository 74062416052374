/* eslint-disable react/no-deprecated */
import React, { Component } from 'react'
import styles from './Error404.module.css'
import messages from './Error404.messages'
import logo from '../../Assets/images/svg/moons-white.svg'
import lunaMobile from '../../Assets/images/svg/lunaMobile.svg'
import lunaDesktop from '../../Assets/images/svg/lunaDesktop.svg'

/**
 * ErrorQuery Component
 * @returns {void} .
 */
class ErrorQuery extends Component {

  /**
   * Constructor
   * @param {Object} props .
   * @returns {void} .
   */
  constructor(props) {
    super(props)

    this.state = {
      moonFooter: ''
    }
  }

  /**
   * Component Will Mount
   * @returns {void} .
   */
  componentWillMount() {
    window.addEventListener('resize', this.changeImage)
  }

  /**
   * Component Did Mount
   * @returns {void} .
   */
  componentDidMount() {
    this.changeImage()
  }

  /**
   * Component Will Unmount
   * @returns {void} .
   */
  componentWillUnmount() {
    window.removeEventListener('resize', this.changeImage)
  }

  changeImage = () => {
    const moonFooter = window.screen.width < 700 ? lunaMobile : lunaDesktop
    this.setState({ moonFooter })
  }

  /**
   * Render Component
   * @returns {void} .
   */
  render() {
    const { moonFooter } = this.state
    const { error } = this.props
    const message = (error && error.message) || messages.noFound

    return (
      <div className={styles.ErrorContainer}>
        <div className={styles.Logo}>
          <img
            alt="logo"
            src={logo}
          />
        </div>
        <div className={styles.Error404}>{messages.error}</div>
        <div className={styles.Subtitle}>{messages.subtitle}</div>
        <div className={styles.NoFound}>{message}</div>
        <div className={styles.MoonsFooter}>
          <img
            className={styles.ImgMoonsFooter}
            alt="moonFooter"
            src={moonFooter}
          />
        </div>
      </div>
    )
  }
}

export default ErrorQuery
