/* eslint-disable camelcase */
/* eslint-disable max-lines */
/* eslint-disable complexity */
import React from 'react'
import moment from 'moment'
import 'moment/locale/es'
import styles from './ChooseDates.module.css'
import messages from './ChooseDates.messages'
import apiAcuity from '../../Api/Acuity'
import Utils from '../../Utils/utils'
import LoaderSection from '../LoaderSection/LoaderSection'
import implant from '../../Assets/images/svg/implant.svg'
import arrow from '../../Assets/images/svg/arrowBack.svg'
import left from '../../Assets/images/svg/left.svg'
import right from '../../Assets/images/svg/right.svg'
import leftGray from '../../Assets/images/svg/leftGray.svg'
import rightGray from '../../Assets/images/svg/rightGray.svg'
import mask from '../../Assets/images/svg/mask.svg'

/**
 * ChooseDates Component
 * @return {void}
 */
class ChooseDates extends React.Component {
  /**
   * Constructor
   * @param {*} props .
   */
  constructor(props) {
    super(props)
    this.state = {
      dates: [],
      labelDates: {},
      loader: true,
      centerDate: 1,
      times: ''
    }
  }

  /**
   * Component Did Mount
   * @return {void}
   */
  componentDidMount = () => {
    this.calculateDates()
  }

  calculateDates = async () => {
    const { branch } = this.props
    const id = branch && branch.Appointment_Type_Id
    const appointment = await apiAcuity.appointmentTypes()
    let dates = []
    let nextDates = []

    const calendar = Array.isArray(appointment) && appointment.find(
      (ap) => ap.id.toString() === id
    )

    if (calendar) {
      const { month, nextMonth } = this.dateToAcuity()
      dates = await apiAcuity.dates(calendar.id, month, calendar.calendarIDs[0])
      if (dates.length < 15) {
        nextDates = await apiAcuity.dates(calendar.id, nextMonth, calendar.calendarIDs[0])
      }
      const { labelDates, newDates } = this.mapDates(dates, nextDates)
      const times = Array.isArray(newDates)
        && await Promise.all(newDates.map(date => this.getOneTime(date, calendar)))
      this.setState({
        dates: newDates, labelDates, times, loader: false
      })
    } else {
      this.setState({ loader: false })
    }
  }

  /**
   * Get One Time
   * @param {Array} date .
   * @param {Object} calendar .
   * @return {void}
   */
  getOneTime = async (date, calendar) => {
    const times = await apiAcuity.times(calendar.id, date, calendar.calendarIDs[0])
    return times
  }

  /**
   * Map Dates
   * @param {Array} dates .
   * @param {Array} nextDate .
   * @return {void}
   */
  mapDates = (dates, nextDate) => {
    const tempDate = dates.concat(nextDate)
    while (tempDate.length > 15) {
      tempDate.pop()
    }
    const labelDates = Array.isArray(tempDate) && tempDate.map(item => {
      const temp = moment(item.date).format('dddd DD/MM')
      return temp
    })

    const newDates = Array.isArray(tempDate) && tempDate.map(item => item.date)
    return { newDates, labelDates }
  }

  dateToAcuity = () => {
    const month = moment().format('YYYY-MM')
    const nextMonth = moment().add(1, 'M').format('YYYY-MM')
    return { month, nextMonth }
  }

  itemChoose = () => {
    const { branch } = this.props
    const {
      Center_Name, Street, Number, Neighborhood
    } = branch
    const address = `${Street} ${Number}, ${Neighborhood}`

    return (
      <div
        className={styles.CenterContainer}
        key={branch.Appointment_Type_Id}
      >
        {branch.Center_Icon ? (
          <img
            className={styles.Icon}
            alt="icon"
            src={branch.Center_Icon}
          />
        ) : (
          <img
            className={styles.Icon}
            alt="icon"
            src={implant}
          />
        )}
        <div className={styles.AddressContainer}>
          <p className={styles.Center}>{Center_Name}</p>
          <p className={styles.Address}>{address}</p>
        </div>
      </div>
    )
  }

  backComponent = () => {
    const { nextStep } = this.props
    return (
      <div
        role="button"
        tabIndex={0}
        className={styles.Back}
        onClick={() => nextStep(true)}
      >
        <img
          alt="logo"
          src={arrow}
        />
        <p>{messages.back}</p>
      </div>
    )
  }

  containerMobile = () => {
    const { times, labelDates, centerDate } = this.state
    const { selectTime, country, branch } = this.props
    const now = moment().format('dddd DD/MM')
    const appointmentTypeId = branch && branch.Appointment_Type_Id? branch.Appointment_Type_Id : false

    return (
      <div
        className={styles.ContainerMobile}
      >
        <div className={styles.Item}>
          <p className={styles.Label}>{labelDates[centerDate - 1] || now}</p>
          {Array.isArray(times) && Array.isArray(times[centerDate - 1])
            && times[centerDate - 1].length > 0 ? times[centerDate - 1].map(item => {
              const time = Utils.getDateByCountryTimeZone(item.time, country, appointmentTypeId)
              return (
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => selectTime(item.time)}
                  key={item.time}
                  className={styles.Time}
                >
                  {time}
                </div>
              )
            }) : (
              <div className={styles.EmptyDate}>
                <img
                  src={mask}
                  alt="mask"
                />
                <p>{messages.noDate}</p>
              </div>
            )}
        </div>
      </div>
    )
  }

  containerDesktop = () => {
    const { times, centerDate, labelDates } = this.state
    const { selectTime, country, branch } = this.props
    const one = moment().format('dddd DD/MM')
    const two = moment().add(1, 'days').format('dddd DD/MM')
    const three = moment().add(2, 'days').format('dddd DD/MM')
    const appointmentTypeId = branch && branch.Appointment_Type_Id? branch.Appointment_Type_Id : false

    return (
      <div className={styles.ContainerDesktop}>
        <div className={styles.Item}>
          <p className={styles.Label}>{labelDates[centerDate - 1] || one}</p>
          {Array.isArray(times) && Array.isArray(times[centerDate - 1])
            && times[centerDate - 1].length > 0 ? times[centerDate - 1].map(item => {
              const time = Utils.getDateByCountryTimeZone(item.time, country, appointmentTypeId)
              return (
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => selectTime(item.time)}
                  key={item.time}
                  className={styles.Time}
                >
                  {time}
                </div>
              )
            }) : (
              <div className={styles.EmptyDateDesk}>
                <p>{messages.noDate}</p>
              </div>
            )}
        </div>
        <div className={styles.Item}>
          <p className={styles.Label}>{labelDates[centerDate] || two}</p>
          {Array.isArray(times) && Array.isArray(times[centerDate])
          && times[centerDate].length > 0 ? times[centerDate].map(item => {
              const time = Utils.getDateByCountryTimeZone(item.time, country, appointmentTypeId)
              return (
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => selectTime(item.time)}
                  key={item.time}
                  className={styles.Time}
                >
                  {time}
                </div>
              )
            }) : (
              <div className={styles.EmptyDateDesk}>
                <p>{messages.noDate}</p>
              </div>
            )}
        </div>
        <div className={styles.Item}>
          <p className={styles.Label}>{labelDates[centerDate + 1] || three}</p>
          {Array.isArray(times) && Array.isArray(times[centerDate + 1])
          && times[centerDate + 1].length > 0
            ? times[centerDate + 1].map(item => {
              const time = Utils.getDateByCountryTimeZone(item.time, country, appointmentTypeId)
              return (
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => selectTime(item.time)}
                  key={item.time}
                  className={styles.Time}
                >
                  {time}
                </div>
              )
            }) : (
              <div className={styles.EmptyDateDesk}>
                <p>{messages.noDate}</p>
              </div>
            )}
        </div>
      </div>
    )
  }

  /**
   * Change Date
   * @param {String} dir .
   * @return {void}
   */
  changeDate = (dir) => {
    const { dates, centerDate } = this.state
    const isDesktop = window.innerWidth > 700

    if (isDesktop) {
      if (dir === 'left' && dates[centerDate - 2]) {
        this.setState({ centerDate: centerDate - 1 })
      } else if (dir === 'right' && dates[centerDate + 2]) {
        this.setState({ centerDate: centerDate + 1 })
      }
      return
    }

    if (dir === 'left' && dates[centerDate - 2]) {
      this.setState({ centerDate: centerDate - 1 })
    } else if (dir === 'right' && dates[centerDate]) {
      this.setState({ centerDate: centerDate + 1 })
    }
  }

  /**
   * Render
   * @returns {void}
   */
  render() {
    const { loader, dates, centerDate } = this.state
    const { name } = this.props
    const welcome = messages.welcome.replace('{Name}', name)
    const isDesktop = window.innerWidth > 700
    let showLeft = !!dates[centerDate - 2]
    let showRight = !!dates[centerDate]

    if (isDesktop) {
      if (!dates[centerDate - 2]) {
        showLeft = false
      }
      if (!dates[centerDate + 2]) {
        showRight = false
      }
    }

    if (loader) {
      return (
        <div className={styles.ContainerLoader}>
          <LoaderSection />
        </div>
      )
    }

    return (
      <div className={styles.ContainerDates}>
        {this.backComponent()}
        {isDesktop && <p className={styles.TitleName}>{welcome}</p>}
        {this.itemChoose()}
        <p className={styles.Select}>{messages.select}</p>
        <div className={styles.DatesContainer}>
          <div className={styles.AllDates}>
            <div
              role="button"
              tabIndex={0}
              className={styles.ImageArrow}
              onClick={() => this.changeDate('left')}
            >
              {showLeft ? (
                <img
                  className={styles.Left}
                  alt="logo"
                  src={left}
                />
              ) : (
                <img
                  className={styles.Left}
                  alt="logo"
                  src={leftGray}
                />
              )}
            </div>
            {isDesktop ? this.containerDesktop() : this.containerMobile()}
            <div
              role="button"
              tabIndex={0}
              onClick={() => this.changeDate('right')}
              className={styles.ImageArrow}
            >
              {showRight ? (
                <img
                  className={styles.Right}
                  alt="logo"
                  src={right}
                />
              ) : (
                <img
                  className={styles.Right}
                  alt="logo"
                  src={rightGray}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ChooseDates
