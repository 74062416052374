const addUtm = (fieldsArray) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.utm_campaign) {
        fieldsArray.push({
        id: process.env.REACT_APP_UTM_CAMPAIGN_FIELD_ID, 
        value: params.utm_campaign,
        });
    }
    if (params.utm_source) {
        fieldsArray.push({
        id: process.env.REACT_APP_UTM_SOURCE_FIELD_ID,
        value: params.utm_source,
        });
    }
    if (params.utm_medium) {
        fieldsArray.push({
        id: process.env.REACT_APP_UTM_MEDIUM_FIELD_ID,
        value: params.utm_medium,
        });
    }
    if (params.utm_term) {
        fieldsArray.push({
        id: process.env.REACT_APP_UTM_TERM_FIELD_ID,
        value: params.utm_term,
        });
    }
    if (params.utm_content) {
        fieldsArray.push({
        id: process.env.REACT_APP_UTM_CONTENT_FIELD_ID,
        value: params.utm_content,
        });
    }
    if (params.utm_reason) {
        fieldsArray.push({
        id: process.env.REACT_APP_APPOINTMENT_REASON_FIELD_ID,
        value: params.utm_reason,
        });
    }
    if (params.services) {
        fieldsArray.push({
        id: process.env.REACT_APP_APPOINTMENT_REASON_FIELD_ID,
        value: params.services,
        });
    }
    return fieldsArray;
    };
  
export default addUtm
