/**
 * Make a request
 * @param {string} url - url to create a request
 * @param {Object} options - Body request
 * @param {Function} success .
 * @param {Function} error .
 * @returns {Object} return object
 */
export const callApi = async (url, options, success, error) => {
  try {
    const response = await fetch(url, options)
    const data = await response.json()
    if (response.ok) {
      success && success(data)
    } else {
      error && error(data)
    }
  } catch (err) {
    error && error(err)
  }
}

/**
 * Call Api Directly
 * @param {string} url - url to create a request
 * @param {Object} options - Body request
 * @returns {Object} return object
 */
export const callApiDirectly = async (url, options) => {
  try {
    const response = await fetch(url, options)
    return response.json()
  } catch (error) {
    return error
  }
}

/**
 * Create a Basic Authorization Header
 * @returns {Object} {Authorization, Content-Type}
 */
export const basicAuthHeader = () => {
  const user = process.env.REACT_APP_BASIC_AUTH_USERNAME
  const password = process.env.REACT_APP_BASIC_AUTH_PASSWORD
  const auth = `${user}:${password}`
  const authEncoded = `Basic ${Buffer.from(auth).toString('base64')}`
  return {
    Authorization: authEncoded,
    'Content-Type': 'application/json',
  }
}
