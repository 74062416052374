import React from 'react'
import styles from './LoaderSection.module.css'

/**
 * LoaderSection component.
 * @returns {void} .
 */
const LoaderSection = () => (
  <div className={styles.LdsRoller}>
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
  </div>
)

export default LoaderSection
